import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { Item } from "control-types";
import { TextWithLabel } from "components-ts/text";
import { default as Controller } from "./Controller";

type FormTextProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = FormControlProps<string, TFieldValues, TName> & {
    adornment?: {
        position: "start" | "end";
        value: React.ReactNode;
    };
    numberType?: "decimal" | "numeric";
    multiple?: boolean;
    items?: Item[];
};

const FormText = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    adornment,
    numberType,
    multiple,
    items,
    width,
    emptyValue = "",
    warning,
    manualUnregister,
}: FormTextProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field, fieldState }) => {
                const { error } = fieldState;
                const { value, required } = field;

                return (
                    <TextWithLabel
                        value={value ?? emptyValue}
                        label={label}
                        adornment={adornment}
                        numberType={numberType}
                        multiple={multiple}
                        items={items}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        warning={warning}
                        required={required}
                    />
                );
            }}
            defaultValue={null}
            width={width ?? "auto"}
            manualUnregister={manualUnregister}
        />
    );
};

export default FormText;
