import roles from "consts/roles";
import { orderBy } from "utils/extensions/arrayExtensions";
import { isNumber } from "lodash";
import { createSelector } from "reselect";

const autocompleteSelector = (state) => state.autocomplete;

export const campaingsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.campaigns);

export const vendorsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.vendors);

export const vendorsGoodsSelector = createSelector(autocompleteSelector, (autocomplete) =>
    autocomplete.vendors.filter((v) => v.isGoodsVendor === true)
);

export const brandsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.brands);

export const brandNamesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.brandNames);

export const subbrandsSelector = createSelector(brandsSelector, (brands) => brands.filter((o) => o.isSubbrand));

export const simlyfiedSubbrandsSelector = createSelector(subbrandsSelector, (brands) => brands.map((o) => ({ brandId: o.brandId, name: o.name })));

export const feedFormatsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.feedFormats);

export const contentCategoriesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.contentCategories);

export const productsProducersSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.productsProducers);

export const productsSuppliersSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.productsSuppliers);

export const productsBrandsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.productsBrands);

export const productsTagsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.productsTags);

export const shopConfigurationsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.shopConfigurations);

export const productsCategoriesSelector = createSelector(autocompleteSelector, (autocomplete) => {
    let categories = [];
    for (var key in autocomplete.productsCategories) {
        categories.push({ id: key, name: autocomplete.productsCategories[key] });
    }

    return categories;
});

export const documentCategoriesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.documentCategories);

export const documentTypesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.documentTypes);
export const costReasonsSelector = createSelector(autocompleteSelector, (autocomplete) =>
    orderBy(autocomplete.costReasons || [], (x) => {
        if (x.split(".") && isNumber(Number(x.split(".")[0]))) {
            return Number(x.split(".")[0]);
        }

        return x;
    })
);

export const userInfoSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.userInfos);

export const userInfoWithRoleSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.userInfosWithRole);

export const b2bUserNames = createSelector(autocompleteSelector, (autocomplete) => {
    let users = [];
    if (autocomplete.userInfosWithRole?.BusinessSupport !== undefined) {
        users = users.concat(
            autocomplete.userInfosWithRole.BusinessSupport.map((user) => {
                return {
                    name: user.fullName.firstName + " " + user.fullName.lastName,
                    value: user.fullName.firstName + " " + user.fullName.lastName,
                    isActive: user.isActive,
                };
            })
        );
    }
    if (autocomplete.userInfosWithRole?.BusinessSupportManager !== undefined) {
        users = users.concat(
            autocomplete.userInfosWithRole.BusinessSupportManager.map((user) => {
                return {
                    name: user.fullName.firstName + " " + user.fullName.lastName,
                    value: user.fullName.firstName + " " + user.fullName.lastName,
                    isActive: user.isActive,
                };
            })
        );
    }
    return users;
});

export const contactTypesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.contactTypes);

export const cnCodesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.cnCodes);

export const analyticGroupsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.analyticGroups);

export const productAnalyticGroupsSelector = createSelector(autocompleteSelector, (autocomplete) => {
    let analyticGroups = [];
    const addElem = (prefix, parentId) => {
        const next = autocomplete.analyticGroups.filter((x) => x.parentId === parentId);
        for (const elem of next) {
            const nextPrefix = `${prefix} -> ${elem.name}`;
            analyticGroups.push({ id: elem.id, name: nextPrefix, isActive: elem.isActive });
            addElem(nextPrefix, elem.id);
        }
    };
    for (var elem of autocomplete.analyticGroups.filter((x) => x.level === 1)) {
        analyticGroups.push({ id: elem.id, name: elem.name, isActive: elem.isActive});
        addElem(elem.name, elem.id);
    }
    return analyticGroups;
});

export const documentStatusesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.documentStatuses);

export const vendorRecipientsSelector = createSelector(autocompleteSelector, (autocomplete) =>
    autocomplete.vendorRecipients?.map((r) => ({ ...r, name: `[${r.recipientId}] - ${r.name}` }))
);

export const vendorUsersSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.vendorUsers);

export const vendorSubsidiaryGainUsersSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.vendorSubsidiaryGainUsers);

export const vendorSubsidiaryGainStatusesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.vendorSubsidiaryGainStatuses);

export const pimAutocompleteSelector = createSelector(autocompleteSelector, (autocomplete) => {
    return {
        analyticGroups: autocomplete.analyticGroups,
        brands: autocomplete.brands,
        cnCodes: autocomplete.cnCodes,
        cnCodesDict: autocomplete.cnCodes
            ? autocomplete.cnCodes.reduce((map, obj) => {
                  map[obj.cnStake] = obj;
                  return map;
              }, {})
            : {},
        userInfos: autocomplete.userInfosWithRole[roles.Catman],
        producers: autocomplete?.vendors?.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)).filter((o) => o.isProducer),
    };
});

export const documentDefinitionsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.documentDefinitions);

export const countriesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.countries);

export const activePromotionPeriodsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.activePromotionPeriods);

export const promotionPeriodsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.promotionPeriods);

export const marketingToolsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.marketingTools);

export const toolkitNamesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.toolkitNames);

export const toolkitsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.toolkits);

export const membershipSeasonsSelector = createSelector(autocompleteSelector, (autocomplete) =>
    Object.values(autocomplete.membershipSeasons).sort((m) => m.season)
);

export const employeeDepartmentsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.employeeDepartments);

export const employeesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.employees);

export const procedureCategoriesSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.procedureCategories);
export const reservationGroupsSelector = createSelector(autocompleteSelector, (autocomplete) => autocomplete.reservationGroups);
