import { useCreateOrUpdateMutation, useGetQuery } from "api/hooks";
import { CategoryTree, CategoryTreeStructure, FindCategoryTreesRequest, FindCategoryTreesResponse } from "Content-Category";

const queryKey = "api/v1/categories/trees/";

export const useGetActiveCategoryTreeWithoutStructure = () => {
    return useGetQuery<Array<CategoryTree>, {}>({
        app: "content",
        url: queryKey + "active/no-structure",
    });
};

export const useGetActiveCategoryTree = () => {
    return useGetQuery<Array<CategoryTreeStructure>, {}>({
        app: "content",
        url: queryKey + "active",
    });
};

export const useGetCategoryTrees = (initailParams: FindCategoryTreesRequest) => {
    return useGetQuery<FindCategoryTreesResponse, FindCategoryTreesRequest>({
        app: "content",
        url: queryKey,
        queryParams: initailParams,
    });
};

export const useGetCategoryTree = (id: string | undefined) => {
    return useGetQuery<CategoryTreeStructure>({ app: "content", url: queryKey + (id ? id : ""), shouldMakeRequest: Boolean(id) });
};

export const useUpdateCategoryTree = (id: string | undefined) => {
    return useCreateOrUpdateMutation<CategoryTreeStructure, CategoryTreeStructure>({ app: "content", url: queryKey + (id ? id : ""), id });
};
