import { GridCellModes, GridRowSelectionModel } from "@mui/x-data-grid";
import { DataGrid } from "components-ts/controls";
import { DataGridActionColumn, DataGridColumn, SelectProps, TextFieldProps } from "control-types";
import React from "react";
import { FieldPath, useFieldArray, useFormContext } from "react-hook-form";
import { NestedKeyOf } from "Shared";
import FormDate from "./FormDate";
import FormDateTime from "./FormDateTime";
import FormDecimal from "./FormDecimal";
import FormInteger from "./FormInteger";
import FormSelect from "./FormSelect";
import FormSwitch from "./FormSwitch";
import FormText from "./FormText";
import FormTextField from "./FormTextField";

type FormDataGridProps<TValue extends { id: string }> = {
    name: string;
    columns: FormDataGridColumn<TValue>[];
    defaultValue?: Partial<Omit<TValue, "id">>;
    canAddItem?: boolean;
    canRemoveItem?: boolean | "disabled";
    checkboxSelection?: boolean;
    copyButtonText?: string;
    deleteButtonText?: string;
    canCopyItemValues?: boolean;
    handleRowCopyValues?: (row: TValue, rowIndex: number) => void;
    onRowEditStop?: (row: TValue, rowIndex: number) => void;
    setSelectedRows?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
    additionalButtons?: JSX.Element[];
    autoHeight?: boolean;
    canDeleteMultipleRows?: boolean;
    handleDeleteMultipleRows?: (row: TValue) => void;
    columnHeaderHeight?: number;
    filterFields?: FieldPath<TValue>[];
    disableButtons?: boolean;
};

export type FormDataGridColumn<T extends { id: string }> = Omit<DataGridColumn<T>, "name" | "renderCell" | "renderEditCell"> & {
    property: NestedKeyOf<T>;
    wrapper?: ((props: TextFieldProps) => React.ReactElement<TextFieldProps>) | ((props: SelectProps) => React.ReactElement<SelectProps>);
    onCustomChange?: (value: Date | string | number | (string | number)[] | undefined, name: string) => void;
    disablePast?: boolean;
    emptyValue?: string;
    minDate?: moment.Moment | string;
    maxDate?: moment.Moment | string;
    selectValuesBasedOnFieldName?: boolean;
    minWidth?: number;
    multiple?: true | undefined;
    disableEmpty?: true | undefined;
};

const FormDataGrid = <TValue extends { id: string }>({
    name,
    columns,
    defaultValue,
    canAddItem = true,
    canRemoveItem = true,
    checkboxSelection,
    canCopyItemValues,
    copyButtonText,
    deleteButtonText,
    handleRowCopyValues,
    onRowEditStop,
    setSelectedRows,
    additionalButtons,
    autoHeight = true,
    canDeleteMultipleRows = false,
    handleDeleteMultipleRows,
    columnHeaderHeight,
    filterFields,
    disableButtons = false,
}: FormDataGridProps<TValue>): JSX.Element => {
    const { getValues, unregister } = useFormContext();
    const { append, remove } = useFieldArray({
        name,
    });
    const rows = ((getValues(name) || []) as TValue[]).map((item) => {
        return JSON.parse(JSON.stringify(item)) as TValue;
    });

    const getIndexByRow = (arr: TValue[], id: string | number) => {
        return arr ? arr.findIndex((r) => r.id === id) : -1;
    };

    const cols = columns.map(
        (c) =>
            ({
                ...c,
                name: c.property,
                minWidth: c.minWidth,
                renderCell: ({ id }) => {
                    const rowIndex = getIndexByRow(getValues(name), id);
                    if (rowIndex === -1) {
                        return undefined;
                    }

                    return (
                        <FormText
                            name={`${name}.${rowIndex}.${c.property}`}
                            label=""
                            items={c.items}
                            width={"fullWidth"}
                            emptyValue={c.emptyValue}
                        />
                    );
                },
                renderEditCell: ({ cellMode, id }) => {
                    const rowIndex = getIndexByRow(getValues(name), id);
                    if (rowIndex === -1) {
                        return undefined;
                    }

                    const fieldName = `${name}.${rowIndex}.${c.property}`;
                    if (cellMode === GridCellModes.View || c.readOnly) {
                        return (
                            <FormText
                                name={fieldName}
                                label=""
                                items={c.items}
                                width={"fullWidth"}
                            />
                        );
                    }

                    switch (c.type) {
                        case "boolean":
                            return (
                                <FormSwitch
                                    name={fieldName}
                                    label=""
                                    width={"fullWidth"}
                                    useGridItem={false}
                                />
                            );
                        case "date":
                            return (
                                <FormDate
                                    name={fieldName}
                                    label=""
                                    width={"fullWidth"}
                                    useGridItem={false}
                                    onCustomChange={(value, name) => {
                                        if (c.onCustomChange) {
                                            c.onCustomChange(value, name);
                                        }
                                    }}
                                    disablePast={c.disablePast}
                                    minDate={c.minDate}
                                    maxDate={c.maxDate}
                                />
                            );
                        case "dateTime":
                            return (
                                <FormDateTime
                                    name={fieldName}
                                    label=""
                                    width={"fullWidth"}
                                    useGridItem={false}
                                />
                            );
                        case "decimal":
                            return (
                                <FormDecimal
                                    name={fieldName}
                                    label=""
                                    width={"fullWidth"}
                                    useGridItem={false}
                                    onCustomChange={c.onCustomChange}
                                />
                            );
                        case "integer":
                            return (
                                <FormInteger
                                    name={fieldName}
                                    label=""
                                    width={"fullWidth"}
                                    useGridItem={false}
                                />
                            );
                        case "select":
                            return (
                                <FormSelect
                                    name={fieldName}
                                    label=""
                                    items={(c.selectValuesBasedOnFieldName ? getValues(`${fieldName}Options`) : c.items) ?? []}
                                    wrapper={c.wrapper as (props: SelectProps) => React.ReactElement<SelectProps>}
                                    width={"fullWidth"}
                                    useGridItem={false}
                                    size={c.width}
                                    onCustomChange={(value, name) => {
                                        if (c.onCustomChange) {
                                            c.onCustomChange(value, name);
                                        }
                                    }}
                                    multiple={c.multiple}
                                    disableEmpty={c.disableEmpty}
                                />
                            );
                        default:
                            return (
                                <FormTextField
                                    name={fieldName}
                                    label=""
                                    wrapper={c.wrapper as (props: TextFieldProps) => React.ReactElement<TextFieldProps>}
                                    width={"fullWidth"}
                                    useGridItem={false}
                                />
                            );
                    }
                },
            }) as DataGridColumn<TValue>
    );

    const getRowActions = (): DataGridActionColumn<TValue>[] => {
        const actions: DataGridActionColumn<TValue>[] = [];
        if (canRemoveItem) {
            actions.push({
                actionType: "delete",
                onClick: (id) => {
                    const rowIndex = getIndexByRow(getValues(name), id);
                    if (rowIndex > -1) {
                        remove(rowIndex);
                        unregister(`${name}.${rows.length - 1}`);
                    }
                },
                disabled: canRemoveItem === "disabled",
            });
        }

        return actions;
    };

    const copyRowValues = (ids: GridRowSelectionModel, rows: TValue[]) => {
        if (handleRowCopyValues) {
            ids.forEach((id) => {
                const rowIndex = getIndexByRow(getValues(name), id);
                if (rowIndex > -1) {
                    handleRowCopyValues(rows[rowIndex], rowIndex);
                }
            });
        }
    };

    const deleteRows = (ids: GridRowSelectionModel, rows: TValue[]) => {
        if (canDeleteMultipleRows) {
            ids.map((id) => getIndexByRow(getValues(name), id))
                .sort((a, b) => b - a)
                .forEach((rowIndex, i) => {
                    if (rowIndex > -1) {
                        remove(rowIndex);
                        unregister(`${name}.${rows.length - (i + 1)}`);
                        if (handleDeleteMultipleRows) {
                            handleDeleteMultipleRows(rows[rowIndex]);
                        }
                    }
                });
        }
    };

    return (
        <DataGrid<TValue>
            key={name}
            rows={rows}
            columns={cols}
            visibleColumnsKey={`${name}-visibleColumns`}
            addItem={canAddItem ? (id) => append({ id, ...defaultValue }) : undefined}
            getRowActions={canRemoveItem ? getRowActions : undefined}
            checkboxSelection={checkboxSelection}
            canCopyItemValues={canCopyItemValues}
            copyButtonText={copyButtonText}
            deleteButtonText={deleteButtonText}
            handleCopyValues={copyRowValues}
            onRowEditStop={
                onRowEditStop
                    ? (row) =>
                          onRowEditStop(
                              row,
                              rows.findIndex((o) => o.id === row.id)
                          )
                    : undefined
            }
            setSelectedRows={setSelectedRows}
            additionalButtons={additionalButtons}
            autoHeight={autoHeight}
            handleDeleteRows={deleteRows}
            columnHeaderHeight={columnHeaderHeight}
            filterFields={filterFields}
            disableButtons={disableButtons}
        />
    );
};

export default FormDataGrid;
