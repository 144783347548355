import { Alert } from "components/Alerts";
import Button from "components/Button/Button";
import { FilePreview } from "components/Files/FilePreview";
import { ImageCard } from "components/ImageGallery/ImageCard";
import { ImageFiles, PdfFile } from "consts/fileTypes";
import { Field } from "redux-form";
import { retryContainerScreenshotting } from "store/vendors/subsidiaryGains/action";
import { makeStyles } from "tss-react/mui";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CloudDownload, Delete, ImageSearch } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { orderBy, uniqueId } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import ImageDrop from "./ImageDrop";

const useStyles = makeStyles()({
    marginTop: {
        marginTop: "20px",
    },
});

const readImage = (file) => {
    return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
            resolve({ file, url: fr.result });
        };
        fr.onerror = function () {
            reject(fr);
        };
        fr.readAsDataURL(file);
    });
};

const maxFileSizeMB = 10;
const maxFileSize = maxFileSizeMB * 1024 * 1024;

export const SubsidiaryGainServiceConfirmationsField = ({
    form,
    formSection,
    name,
    error = "",
    vendorId = "",
    sgId = "",
    containerId = "",
    sectionName = "",
    ...props
}) => {
    const { t, common } = useCommonTranslation();
    const change = useChange(form);
    const fieldName = [formSection, name].filter(Boolean).join(".");
    const confirmations = useFormValueSelector(form, fieldName, []);
    const [imageUploadInProgress, setImageUploadInProgress] = useState(false);
    const dispatch = useDispatch();

    const handleImagesAdded = async (acceptedFiles) => {
        setImageUploadInProgress(true);
        var newConfirmations = (await Promise.all(acceptedFiles.map(readImage))).map((image) => {
            return {
                isPdf: image.file.type === PdfFile.header,
                isNew: true,
                blobName: uniqueId("unsaved"),
                imageUrl: image.url,
                imageFile: image.file,
            };
        });

        change(fieldName, [...confirmations, ...newConfirmations]);

        setImageUploadInProgress(false);
    };

    const handleRemoveConfirmation = (blobName) => {
        change(
            fieldName,
            confirmations.filter((confirmation) => confirmation.blobName !== blobName)
        );
    };

    const retry = () => {
        dispatch(retryContainerScreenshotting(vendorId, sgId, containerId, sectionName));
        error = "";
    };

    return (
        <>
            {!!error && confirmations.length === 0 && (
                <>
                    <Alert
                        open={!!error}
                        severity="error"
                        text={t(common.screenshotingErrorMessage) + error}
                        title={t(common.screenshotingError)}
                    />
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={retry}
                    >
                        {t(common.retry)}
                    </Button>
                </>
            )}
            <Field
                name={name}
                component={SubsidiaryGainServiceConfirmations}
                confirmations={confirmations}
                handleImagesAdded={handleImagesAdded}
                handleDelete={handleRemoveConfirmation}
                imageUploadInProgress={imageUploadInProgress}
                {...props}
            />
        </>
    );
};

export const SubsidiaryGainServiceConfirmations = ({ confirmations, handleImagesAdded, imageUploadInProgress, ...props }) => {
    const { classes } = useStyles();
    const [confirmationPreview, setConfirmationPreview] = useState({
        open: false,
    });
    const [sizeyFiles, setSizeyFiles] = useState([]);

    const handlePreviewConfirmation = (confirmation) => setConfirmationPreview({ ...confirmation, open: true });

    const onDropAccepted = (files) => {
        setSizeyFiles([]);
        handleImagesAdded(files);
    };

    const onDropRejected = (files) => {
        setSizeyFiles(files.map((file) => file.name));
    };

    return (
        <Grid container>
            <FilePreview
                open={confirmationPreview.open}
                file={confirmationPreview.imageUrl}
                isPdf={confirmationPreview.isPdf}
                onClose={() =>
                    setConfirmationPreview({
                        ...confirmationPreview,
                        open: false,
                    })
                }
                onClosed={() => setConfirmationPreview({ open: false })}
                actions={[
                    !confirmationPreview.isNew && {
                        icon: <CloudDownload />,
                        onSelect: () => props.handleDownload(confirmationPreview.blobName),
                        keepOpen: true,
                    },
                    !props.readOnly && {
                        icon: <Delete />,
                        onSelect: () => props.handleDelete(confirmationPreview.blobName),
                    },
                ]}
            />
            <Alert
                open={sizeyFiles.length > 0}
                handleClose={() => setSizeyFiles([])}
                severity="error"
                className={classes.marginTop}
                text={sizeyFiles.map((file) => (
                    <>
                        {file} <br />
                    </>
                ))}
                title={tValidation(validation.someFilesExceedMaxFileSize, {
                    maxSize: `${maxFileSizeMB} mb`,
                })}
            />
            <Grid
                container
                direction={"row"}
                className={classes.marginTop}
            >
                {orderBy(confirmations, ["blobName"]).map((confirmation, i) => (
                    <Grid
                        item
                        key={i}
                    >
                        <ServiceConfirmation
                            confirmation={confirmation}
                            handlePreview={handlePreviewConfirmation}
                            {...props}
                        />
                    </Grid>
                ))}
            </Grid>
            {!props.readOnly && (
                <ImageDrop
                    maxFileSize={maxFileSize}
                    onDrop={onDropAccepted}
                    onDropRejected={onDropRejected}
                    imageUploadInProgress={imageUploadInProgress}
                    acceptedFiles={[...ImageFiles, PdfFile]}
                />
            )}
        </Grid>
    );
};

const ServiceConfirmation = ({ confirmation, handleDownload, handleGet, handlePreview, handleDelete, readOnly }) => {
    const { blobName, imageUrl, isNew, isPdf } = confirmation;

    useEffect(() => {
        if (!imageUrl && !isNew) {
            handleGet(blobName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl]);

    return (
        <ImageCard
            key={blobName}
            image={imageUrl}
            isPdf={isPdf}
            onDoubleClick={() => handlePreview && handlePreview(confirmation)}
            imageWidth={120}
            actions={[
                handlePreview && {
                    name: "Podgląd",
                    icon: <ImageSearch />,
                    onSelect: () => handlePreview(confirmation),
                },
                handleDownload &&
                    !confirmation.isNew && {
                        name: "Pobierz",
                        icon: <CloudDownload />,
                        onSelect: () => handleDownload(blobName),
                    },
                !readOnly &&
                    handleDelete && {
                        name: "Usuń",
                        icon: <Delete />,
                        onSelect: () => handleDelete(blobName),
                    },
            ].filter((x) => !!x)}
        />
    );
};
