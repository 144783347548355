import Layout from "components/Grid/Layout";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import roles from "consts/roles";
import { UserContext } from "context/UserContext/UserContext";
import { actions } from "store/vendors/action";
import { vendorListSelector } from "store/vendors/selector";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { common, vendor } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";

const VendorList = ({ vendorPms, customActionColumns, hideHeader }) => {
    const resetFilter = {
        vendorId: undefined,
        vendorName: undefined,
    };
    const { push } = usePush();
    const { t } = useTranslation(["vendor", "common"]);
    const { isInRole } = useContext(UserContext);
    const isVendor = isInRole(roles.ExternalVendor);
    const [filter, setFilter] = React.useState(resetFilter);
    const handleGoToVendor = ({ vendorId }) => {
        push(`/vendors/${vendorId}`);
    };

    //prettier-ignore
    let columns = [
        { key: "vendorId",              value: t(vendor.vendorId),               type: formatTypes.text,     filtrable: !isVendor,   },
        { key: "vendorName",            value: t(vendor.vendorName),             type: formatTypes.text,     filtrable: !isVendor, },
        { key: "cooperationType",       value: t(vendor.cooperationType),        type: formatTypes.translatable, options: { translationKey:"vendor" }     },
        { key: "dateOfStartCooperation",value: t(vendor.dateOfStartCooperation), type: formatTypes.time,         options: { timeFormat: timePrecisonFormats.days },    },
        { key: "dateOfEndCooperation",  value: t(vendor.dateOfEndCooperation),   type: formatTypes.time,         options: { timeFormat: timePrecisonFormats.days }, },
        { key: "isActive",              value: t(`common:${common.isActive}`), type: formatTypes.boolean },
    ];

    if (customActionColumns) {
        columns = columns.concat(customActionColumns);
    } else {
        columns = columns.concat({
            key: "action",
            value: "",
            type: formatTypes.action,
            action: handleGoToVendor,
            actionType: tableActionTypes.preview,
        });
    }

    const { isLoading } = vendorPms;
    const handleFilter = useDebouncedCallback((column, value) => {
        if (column === undefined && value === undefined) {
            setFilter(resetFilter);
            return;
        }

        if (column === "vendorId") {
            setFilter({ vendorId: value, vendorName: filter.vendorName });
        }

        if (column === "vendorName") {
            setFilter({ vendorId: filter.vendorId, vendorName: value });
        }
    }, 300);

    return (
        <Layout
            main
            headerText={t(vendor.Vendors)}
            hideHeader={hideHeader}
        >
            {/* {!isVendor && (
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => {
                                push(`/vendors/operation/new`);
                            }}
                        >
                            {t(vendor.add)}
                        </Button>
                    </Grid>
                </Grid>
            )} */}
            <TableWithToggleFilter
                isLoading={isLoading}
                columns={columns}
                dataSelector={vendorListSelector}
                dataSourceAction={actions.getVendorList}
                canAddNewItem={false}
                filterFunction={handleFilter}
                filterValue={filter}
                initialFilterValue={resetFilter}
                shoudlHandleDoubleClick
                // pagination={!isVendor}
            />
        </Layout>
    );
};

const mapStateToProps = (state) => {
    let { vendorPms } = state;
    return { vendorPms };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
