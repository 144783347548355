import { GetRequest, get } from "utils/extensions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import types from "./types";

export const getCampaigns = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/shared/campaigns`, types.campaigns.all)));
};

export const getBrands = () => async (dispatch) => {
    return await dispatch(get(new GetRequest("api/autocomplete/brands", types.brands.all)));
};

export const getBrandNames = () => async (dispatch) => {
    return await dispatch(get(new GetRequest("api/autocomplete/brand-names", types.brandNames.all)));
};

export const getActiveBrandNames = () => async (dispatch) => {
    return await dispatch(get(new GetRequest("api/autocomplete/active-brand-names", types.brandNames.all)));
};

export const getVendors = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/vendors`, types.vendors.all)));
};

export const getArchiveVendors = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/archive-vendors`, types.vendors.all)));
};

export const getFeedFormats = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/offer/feeds/formats`, types.feedFormats.all)));
};

export const getContentCategories = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/content/containers/categories`, types.contentCategories.all)));
};

export const getProductsSuppliers = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/offer/products/suppliers`, types.productsSuppliers.all)));
};

export const getProductsProducers = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/offer/products/producers`, types.productsProducers.all)));
};

export const getProductsBrands = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/offer/products/brands`, types.productsBrands.all)));
};

export const getProductsTags = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/offer/products/tags`, types.productsTags.all)));
};

export const getProductsCategories = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/offer/categories/names`, types.productsCategories.all)));
};

export const getContentContainers = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/content/containers/containers`, types.contentContainers.all)));
};

export const getCostReasons = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/cost-reasons`, types.costReasons.all)));
};

export const getUserInfo = (params) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/identity/user-infos`, types.userInfos.all, { queryParams: params })));
};

export const getContactTypes = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/job-positions`, types.contactTypes.all)));
};

export const getCnCodes = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/cn-stakes`, types.cnCodes.all)));
};

export const getUserInfoWithRole = (role, params) => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(`api/identity/user-infos/${role}`, types.userInfosWithRole.all, {
                queryParams: params,
            })
        )
    );
};

export const getUserInfoWithRoles = (roles) => async (dispatch, getState) => {
    for (const role of roles) {
        const state = getState().autocomplete.userInfosWithRole[role];
        if (state && state[0].lastFetched) {
            const now = new Date();
            const lastFetched = new Date(state[0].lastFetched);
            if (now.getDay() == lastFetched.getDay() && now.getHours() - lastFetched.getHours() < 3) {
                console.log(`${role}: get data from last feteched`);
                continue;
            }
        }

        dispatch(get(new GetRequest(`api/identity/user-infos/${role}`, types.userInfosWithRole.all, [])));
    }
};

export const getAnalyticGroups = (params) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/analytic-groups`, types.analyticGroups.all, { queryParams: params})));
};

export const getShopConfigurations = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/shop-configuration/all`, types.shopConfigurations.all)));
};

export const getDocumentCategories = (params) => async (dispatch) => {
    return await dispatch(
        get(new GetRequest(`api/autocomplete/archive-documents/categories`, types.documentCategories.all, { queryParams: params }))
    );
};

export const getDocumentTypes = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/archive-documents/types`, types.documentTypes.all)));
};

export const getVendorRecipients = (vendorId) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/vendor/recipients/${vendorId}`, types.vendorRecipients.all)));
};

export const getSubsidiaryGainUsers = (vendorId) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/vendor/${vendorId}/subsidiary-gains/users`, types.vendorSubsidiaryGainUsers.all)));
};

export const getSubsidiaryGainStatuses = (vendorId) => async (dispatch) => {
    return await dispatch(
        get(new GetRequest(`api/autocomplete/vendor/${vendorId}/subsidiary-gains/statuses`, types.vendorSubsidiaryGainStatuses.all))
    );
};

const getPromotionProductsAutocompletes = () => async (dispatch) => {
    dispatch(getProductsSuppliers());
    dispatch(getProductsProducers());
    dispatch(getProductsCategories());
    dispatch(getProductsBrands());
    dispatch(getProductsTags());
};

export const usePromotionFormAutocompleteEffect = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPromotionProductsAutocompletes());
    }, [dispatch]);
};

export const useAnalyticGroupsAndProductsBrandsEffect = ({ disable }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (disable) {
            dispatch(getAnalyticGroups());
            dispatch(getProductsBrands());
        }
    }, [dispatch, disable]);
};

export const useContactTypeEffect = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactTypes());
    }, [dispatch]);
};

export const getDocumentDefinitions = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/document-definitions`, types.documentDefinitions.all)));
};

export const getCountries = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/shared/countries`, types.countries.all)));
};

export const getEmployeeDepartments = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/employee-departments`, types.employeeDepartments.all)));
};

export const getEmployees = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/employees`, types.employees.all)));
};

export const getPromotionPeriodsFromDate = (params) => async (dispatch) => {
    params.dateFrom = params.dateFrom ?? moment().format("YYYY-MM-DD");
    return await dispatch(
        get(
            new GetRequest(`api/autocomplete/promotion-periods`, types.activePromotionPeriods.all, {
                queryParams: params,
            })
        )
    );
};

export const getAllPromotionPeriods = (params) => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(`api/autocomplete/all-promotion-periods`, types.promotionPeriods.all, {
                queryParams: params,
            })
        )
    );
};

export const getActiveMarketingToolNames = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/marketing-tools`, types.marketingTools.all)));
};
export const getMarketingTools = () => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(`api/autocomplete/marketing-tools`, types.marketingTools.all, {
                queryParams: { inactive: true },
            })
        )
    );
};
export const getToolkitNames = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/toolkit-names`, types.toolkitNames.all)));
};

export const getActiveToolkits = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/toolkits`, types.toolkits.all)));
};

export const getToolkits = () => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(`api/autocomplete/toolkits`, types.toolkits.all, {
                queryParams: { inactive: true },
            })
        )
    );
};

export const getMembershipSeasons = () => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/v1/autocomplete/shared/membership-seasons`, types.membershipSeasons.all)));
};

export const getProcedureCategories = (params) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/autocomplete/procedure-categories`, types.procedureCategories.all, { queryParams: params })));
};

export const getReservationGroups = (params) => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(`api/autocomplete/reservation-groups`, types.reservationGroups.all, {
                queryParams: params,
            })
        )
    );
};

export const setReservationGroups = (params) => (dispatch) => {
    return dispatch({
        type: types.setReservationGroups,
        payload: params,
    });
};
