import fileTypes from "consts-ts/fileTypes";
import { makeStyles } from "tss-react/mui";
import React from "react";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Backdrop, Card, CardContent, CardMedia, Fade, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Modal } from "@mui/material";
import { Fetcher, Query } from "api-types";
import { downloadFile } from "utils-ts/functions";
import { SpinningPreloader } from "components-ts/preloaders";

const imageWidth = 200;
const useStyles = makeStyles()((theme) => ({
    imageCard: {
        width: imageWidth + "px",
        height: imageWidth + "px",
        marginRight: "20px",
        marginBottom: "20px",
        borderRadius: 6,
    },
    imageCardContent: {
        "position": "relative",
        "padding": 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    imageCardOverlay: {
        zIndex: 10,
        backgroundColor: "inherit",
        position: "absolute",
    },
    imageCardOverlayMenuBtn: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    imageCardOverlayMainIcon: {
        padding: "12px",
        position: "absolute",
        top: "0px",
        left: "0px",
        color: theme.palette.primary.main,
    },
    imageCardImageContainer: {
        display: "flex",
        justifyContent: "center",
    },
    imageCardImage: {
        width: "auto",
        maxHeight: "200px",
        alignSelf: "center",
    },
    imageCardIntegrationLabel: {
        paddingLeft: "6px",
        paddingRight: "6px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontWeight: 500,
        borderWidth: "2px",
        borderRadius: "14px",
        position: "absolute",
        bottom: "4px",
        left: "4px",
    },
    imagePreview: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "24",
    },
    imagePreviewContainer: {
        height: "100%",
        width: "100%",
        justifyContent: "flex-end",
        padding: "10px",
    },
    imagePreviewImage: {
        width: "auto",
        maxHeight: "70vh",
        marginTop: "40px",
    },
}));

type Image = {
    imageId: string;
    fileName: string;
    content?: string;
    uri?: string;
};

type ImageCardProps = {
    image: Image;
    disabled?: boolean;
    useFileQuery: () => Query.UseQueryResult<Fetcher.FileResponse, {}, {}> | string;
    canRemove?: (image: Image) => boolean;
    handleRemove?: (image: Image) => void;
};

const ImageCard = ({ image, useFileQuery, canRemove, handleRemove, disabled = false }: ImageCardProps) => {
    const result = useFileQuery();
    const { classes } = useStyles();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
    const [showPreview, setShowPreview] = React.useState<boolean>(false);
    const menuOpen = Boolean(menuAnchorEl);

    const type = Object.values(fileTypes).filter((f) => f.extension === image.fileName.split(".")[-1]);
    const imageSrc =
        typeof result === "string"
            ? result
            : result.status === "success"
            ? `data:${result.data.fileType};base64,${result.data.fileContent}`
            : `data:${type.length > 0 ? type[0].mimeType : "image/png"};base64,${image.content}`;

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const onMenuRemove = () => {
        closeMenu();
        if (handleRemove) {
            handleRemove(image);
        }
    };

    const onMenuPreview = () => {
        closeMenu();
        setShowPreview(true);
    };

    const onMenuDownload = () => {
        closeMenu();
        downloadFile(imageSrc, image.fileName);
    };

    if (!image.content && typeof result === "object" && result.status === "success") {
        return <SpinningPreloader size={imageWidth} />;
    }

    return (
        <>
            <Card
                className={classes.imageCard}
                onMouseDown={(e) => {
                    e.detail === 2 && e.preventDefault();
                }}
                onDoubleClick={onMenuPreview}
            >
                <CardContent className={classes.imageCardContent}>
                    {!disabled && (
                        <Backdrop
                            className={classes.imageCardOverlay}
                            open
                        >
                            <IconButton
                                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                                className={classes.imageCardOverlayMenuBtn}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                            <Menu
                                onClose={closeMenu}
                                open={menuOpen}
                                anchorEl={menuAnchorEl}
                            >
                                <MenuItem onClick={onMenuPreview}>
                                    <ListItemIcon>
                                        <ImageSearchIcon />
                                    </ListItemIcon>
                                    <ListItemText>Podgląd</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={onMenuDownload}>
                                    <ListItemIcon>
                                        <DownloadIcon />
                                    </ListItemIcon>
                                    <ListItemText>Pobierz</ListItemText>
                                </MenuItem>
                                {canRemove && canRemove(image) && (
                                    <MenuItem onClick={onMenuRemove}>
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        <ListItemText>Usuń</ListItemText>
                                    </MenuItem>
                                )}
                            </Menu>
                        </Backdrop>
                    )}
                    <div className={classes.imageCardImageContainer}>
                        <CardMedia
                            component="img"
                            className={classes.imageCardImage}
                            image={imageSrc}
                        />
                    </div>
                </CardContent>
            </Card>

            <Modal
                open={showPreview}
                onClose={() => setShowPreview(false)}
                onAnimationEnd={() => setShowPreview(false)}
                closeAfterTransition
            >
                <Fade in={showPreview}>
                    <Card className={classes.imagePreview}>
                        <CardContent>
                            <Backdrop
                                className={classes.imageCardOverlay}
                                timeout={500}
                                open={true}
                            >
                                <Grid
                                    container
                                    className={classes.imagePreviewContainer}
                                >
                                    {canRemove && canRemove(image) && (
                                        <Grid item>
                                            <IconButton onClick={onMenuRemove}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <IconButton onClick={onMenuDownload}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Backdrop>
                            <CardMedia
                                component="img"
                                className={classes.imagePreviewImage}
                                image={imageSrc}
                            />
                        </CardContent>
                    </Card>
                </Fade>
            </Modal>
        </>
    );
};

export default ImageCard;
