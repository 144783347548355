import { useGetQuery } from "api/hooks";
import { ProductAttribute } from "Content-ProductAttribute";

const queryKey = "api/v1/product-attributes";

export const useGetActiveProductAttributes = () => {
    return useGetQuery<Array<ProductAttribute>, {}>({
        app: "content",
        url: queryKey + "/active",
    });
};
