import { FieldArray, formValueSelector } from "redux-form";
import { withFormName } from "utils/hoc";
import Validator, { notEmptyArrayValidator } from "utils/validators/basic";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import _, { get, isEmpty } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import Effect from "./EffectForm/Effect";
import ProductPools, { getPromotionPoolCount } from "./ProductPools";

const haveEffectCardinality = (value) => {
    return _.uniq(value.filter((e) => e.effectType === "progressive").map((e) => e?.progressive?.progressiveModifiers?.length)).length <= 1;
};

const haveMatchingProgresiveEffectQuantities = (value) => {
    const [first, ...rest] = value
        ?.filter((e) => e.effectType === "progressive")
        .map((e) => e?.progressive?.progressiveModifiers?.map((o) => Number(o.progressiveQuantity)));

    if (rest) {
        for (const p of rest) {
            if (!_.isEqual(first, p)) {
                return false;
            }
        }
    }

    return true;
};

const onlyOneProductModifier = (value) => {
    const productModifiers = value?.filter((e) => e.effectType === "chosenProductModifier");

    if (productModifiers.length > 1) {
        return false;
    }

    return true;
};

const allOrNoneDiscountModifier = (value) => {
    const discountModifiers = value?.filter((e) => e.effectType === "discountModifier");

    if (discountModifiers.length > 0 && discountModifiers.length !== value.length) {
        return false;
    }

    return true;
};

const validators = {
    effects: (value) =>
        notEmptyArrayValidator(value)
            .must((value) => haveEffectCardinality(value), "Ilość progów w efetktach progresywnych musi być jednakowa.")
            .must((value) => haveMatchingProgresiveEffectQuantities(value), "Ilości w efektach progresywnych muszą być tożsame")
            .must((value) => onlyOneProductModifier(value), "Tylko jeden efekt może być typu 'Wybrany produkt'")
            .must((value) => allOrNoneDiscountModifier(value), "Typ zniżka powinny mieć wszystkie efekty bądź żaden")
            .validate(),
    pools: (value, form, _, path) => {
        const isAny = (obj = {}) => {
            return (
                !isEmpty(obj?.brands) ||
                !isEmpty(obj?.categories) ||
                !isEmpty(obj?.merchants) ||
                !isEmpty(obj?.products) ||
                !isEmpty(obj?.suppliers) ||
                !isEmpty(obj?.tags)
            );
        };

        const requirements = get(form, path.replace(".pools", ".requirements"));
        const promotionType = get(form, path.replace(".pools", ".promotionType"));
        const { minCount, maxCount } = getPromotionPoolCount(promotionType);

        return new Validator(value || [])
            .must(
                (value) => value.length === 0,
                tValidation(validation.mustBeEmpty, {
                    when: "jeśli zostało uzupełnione conajmniej 1 pole z sekcji 'Produkty w koszyku'",
                })
            )
            .when(
                !isEmpty(requirements) &&
                    (isAny(requirements.cartProducts?.unpurchased?.excludeProducts) ||
                        isAny(requirements.cartProducts?.unpurchased?.includeProducts) ||
                        isAny(requirements.cartProducts?.purchased?.excludeProducts) ||
                        isAny(requirements.cartProducts?.purchased?.includeProducts))
            )
            .must(
                (value) => minCount <= value.length,
                tValidation(validation.arrayMustHaveAtLeastItems, {
                    number: minCount,
                })
            )
            .when(Boolean(minCount))
            .must(
                (value) => maxCount >= value.length,
                tValidation(validation.notMoreThanElement, {
                    number: maxCount,
                })
            )
            .when(Boolean(maxCount))
            .validate();
    },
};

const PromotionTypeForm = ({ form, sectionPrefix }) => {
    const {
        effects = [],
        promotionType = "",
        pools,
        requirements,
    } = useSelector((state) => formValueSelector(form)(state, `${sectionPrefix ? `${sectionPrefix}.` : ""}definition`)) || {};

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid
                item
                xs={12}
            >
                <FieldArray
                    effects={effects}
                    name="definition.pools"
                    promotionType={promotionType}
                    formName={form}
                    component={ProductPools}
                    validate={validators.pools}
                    pools={pools}
                    requirements={requirements}
                />
            </Grid>

            <Grid
                item
                xs={12}
            >
                <FieldArray
                    effects={effects}
                    name="definition.effects"
                    promotionType={promotionType}
                    component={Effect}
                    validate={validators.effects}
                />
            </Grid>
        </Grid>
    );
};

export default withFormName(PromotionTypeForm);
